import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import { Chip, Stack } from "@mui/material";
import { type AgentPreferences } from "@src/appV2/Agents/api/useWorkerPreferences/types";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { type Worker } from "@src/appV2/Worker/api/types";
import pluralize from "pluralize";

import { OpenShiftCalendarDistanceFilterDialog } from "./Dialogs/DistanceFilterDialog";
import { OpenShiftCalendarLicenseFilterDialog } from "./Dialogs/LicenseFilterDialog";

interface OpenShiftFiltersProps {
  agent: Worker;
  disabled: boolean;
  filters: AgentPreferences;
  setFilters: (filters: AgentPreferences) => void;
}

export function OpenShiftFilters(props: OpenShiftFiltersProps) {
  const { agent, disabled, filters, setFilters } = props;

  const distanceFilterModalState = useModalState();
  const licenseFilterModalState = useModalState();

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="center" paddingBottom={1}>
        {isDefined(agent.licensesData) &&
          agent.licensesData.length > 1 &&
          isDefined(filters.license) && (
            <Chip
              color="primary"
              icon={<FilterIcon />}
              aria-label="Update License"
              label={<b>{filters.license}</b>}
              disabled={disabled}
              onClick={() => {
                licenseFilterModalState.openModal();

                logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_OPEN_FILTER_LICENSE, {
                  agent: agent.userId,
                });
              }}
            />
          )}

        <Chip
          color="primary"
          icon={<FilterIcon />}
          aria-label="Update Distance"
          label={
            <b>
              {filters.distance} {pluralize("mile", filters.distance)}
            </b>
          }
          disabled={disabled}
          onClick={() => {
            distanceFilterModalState.openModal();

            logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_OPEN_FILTER_DISTANCE, {
              agent: agent.userId,
            });
          }}
        />
      </Stack>

      <OpenShiftCalendarDistanceFilterDialog
        modalState={distanceFilterModalState}
        defaultValues={{
          distance: filters.distance,
        }}
        onSubmit={(formData) => {
          setFilters({
            distance: formData.distance,
            license: filters.license,
          });

          logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_SUBMIT_FILTER_DISTANCE_DIALOG, {
            agent: agent.userId,
            distance: formData.distance,
          });
        }}
      />

      <OpenShiftCalendarLicenseFilterDialog
        modalState={licenseFilterModalState}
        agentLicenses={agent?.licensesData ?? []}
        defaultValues={{
          license: filters.license,
        }}
        onSubmit={(formData) => {
          setFilters({
            license: formData.license,
            distance: filters.distance,
          });

          logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_SUBMIT_FILTER_LICENSE_DIALOG, {
            agent: agent.userId,
            license: formData.license,
          });
        }}
      />
    </>
  );
}
